import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import { API, Auth } from 'aws-amplify';
import { toast } from 'react-toastify';
import { ProgressBar } from '../../components/ProgressBar';
import { useServerSideKeys } from '../../handlers/useServerSideKeys';
import { useWebAppKeys } from '../../handlers/useWebAppKeys';
import { useAuth } from '../../hooks/useAuth';
import { AllSecrets } from '../../types';
import {
  GetStarted,
  Control,
  ServerSideKeysForm,
  ClientSideKeys,
  Overview,
  ServerSideInstall,
  Install,
  ClientSideInstall,
} from './Views';
import { removeCloseAppWarningEvent } from '../../helpers/closeAppWarning';

function SetupMarketingCloud() {
  const [creatingSecrets, setCreatingSecrets] = useState(false);

  const authorization = useAuth();
  const serverSideKeys = useServerSideKeys(authorization);
  const webAppKeys = useWebAppKeys();

  const navigate = useNavigate();
  const location = useLocation();
  const pages = useMemo(() => [
    '/',
    '/overview',
    '/first-package',
    '/first-package-keys',
    '/second-package',
    '/second-package-keys',
    '/control',
    '/install'], []);
  const pageAmount = pages.length;

  const currentPage = useMemo(() => location.pathname.slice(location.pathname.lastIndexOf('/')), [location.pathname]);
  const currentPageNumber = useMemo(() => pages.findIndex((page) => page === currentPage), [currentPage, pages]);
  const allSecrets: AllSecrets = useMemo(
    () => ({ ...serverSideKeys.formData, ...webAppKeys.formData }),
    [serverSideKeys.formData, webAppKeys.formData],
  );

  const handleNext = useCallback(
    () => {
      if (currentPageNumber === pageAmount) return;
      navigate(`${pages[currentPageNumber + 1]}`);
    },
    [currentPageNumber, navigate, pageAmount, pages],
  );

  const handleBack = useCallback(
    () => {
      if (currentPageNumber === 0) return;
      navigate(`${pages[currentPageNumber - 1]}`);
    },
    [currentPageNumber, navigate, pages],
  );

  const handleCreate = useCallback(
    async () => {
      if (!serverSideKeys.isValid || !webAppKeys.isValid) {
        toast.error('Could not store keys correctly, please check if you keys are correct in the previous screens');
        return;
      }

      if (!authorization.token || !authorization.tokenBody) {
        toast.error('You need to be logged in.');
        return;
      }
      const currentSession = await Auth.currentSession();
      if (!currentSession.isValid()) {
        toast.error('Your session is expired. Please login again');
        Auth.signOut();
        return;
      }
      setCreatingSecrets(true);

      const apiName = 'setupApi';
      const path = '/setup';

      const initInfo = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: authorization.token,
        },
        body: {
          region: authorization.tokenBody['custom:region'],
          secrets: {
            CLIENT_ID_WEBAPP: allSecrets.clientIdWebApp,
            CLIENT_SECRET_WEBAPP: allSecrets.clientSecretWebApp,
            CLIENT_ID_SERVER: allSecrets.clientIdServer,
            CLIENT_SECRET_SERVER: allSecrets.clientSecretServer,
            AUTH_URI: allSecrets.authURI,
          },
          organization: authorization.tokenBody['custom:organization'],
          enterpriseID: authorization.tokenBody['custom:enterpriseID'],
        },
      };
      try {
        const response = await API.post(apiName, path, initInfo);
        if (!response) {
          throw new Error('Could not store keys correctly.');
        }
        setCreatingSecrets(false);
        toast.success('Keys are successfully stored!');
        removeCloseAppWarningEvent();
        navigate('/install');
      } catch (setupApiError) {
        setCreatingSecrets(false);
        toast.error('Could not store keys correctly, please check if you keys are correct');
      }
    },
    [
      serverSideKeys.isValid,
      webAppKeys.isValid,
      authorization.token,
      authorization.tokenBody,
      allSecrets.clientIdWebApp,
      allSecrets.clientSecretWebApp,
      allSecrets.clientIdServer,
      allSecrets.clientSecretServer,
      allSecrets.authURI,
      navigate,
    ],
  );

  return (
    <Container>
      <Routes>
        <Route path="/" element={<GetStarted handleNext={handleNext} />} />
        <Route path="/overview" element={<Overview handleBack={handleBack} handleNext={handleNext} />} />
        <Route path="/first-package" element={<ServerSideInstall handleBack={handleBack} handleNext={handleNext} />} />
        <Route
          path="/first-package-keys"
          element={(
            <ServerSideKeysForm
              handleBack={handleBack}
              handleNext={handleNext}
              serverSideKeys={serverSideKeys}
            />
        )}
        />
        <Route
          path="/second-package"
          element={(
            <ClientSideInstall
              handleBack={handleBack}
              handleNext={handleNext}
              companyName={authorization.tokenBody?.['custom:organization']}
            />
        )}
        />
        <Route
          path="/second-package-keys"
          element={(
            <ClientSideKeys
              handleBack={handleBack}
              handleNext={handleNext}
              form={webAppKeys}
            />
        )}
        />
        <Route
          path="/control"
          element={(
            <Control
              handleBack={handleBack}
              allSecrets={allSecrets}
              handleCreate={handleCreate}
              creatingSecrets={creatingSecrets}
            />
        )}
        />
        <Route path="/install" element={<Install />} />
      </Routes>
      <ProgressBar progress={(currentPageNumber / (pageAmount - 1)) * 100} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding-top: 40px;
  overflow: hidden;
  flex: 1;
`;

export default SetupMarketingCloud;
