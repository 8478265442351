import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Container, SubTitle, LargeTitle } from '../../../styles/styles';

export function Install() {
  return (
    <Container>
      <LargeTitle>Finished</LargeTitle>
      <SubTitle>You’re all done! Activation Studio is installing</SubTitle>
      <CenterDiv>
        <SubTitle>We will notify you when your app is ready!</SubTitle>
        <SubTitle>You can close this page.</SubTitle>
      </CenterDiv>
      <Image src="/installation/cover-picture.png" alt="2 Happy people in a gradient" />
    </Container>
  );
}

const AnimatedGradient = keyframes`
  0% {background-position:90% 0}
  50% {background-position:10% 100%}
  100% {background-position:90% 0}
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(220deg, rgba(253,55,116,1), rgba(151,71,255,1));
  background-size: 200% 200%;
  animation: ${AnimatedGradient} 7s ease infinite;
`;

const CenterDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;
`;
