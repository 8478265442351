import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 50px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  min-width: 400px;
  border-radius: 10px;
  margin-top: 50px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  padding-top: 20px;
`;

export const LargeTitle = styled.h1`
  font-weight: 600;
  font-size: 40px;
`;

export const SubTitle = styled.h2`
  font-weight: 600;
  font-size: 22px;
  color: #707070
`;

export const ErrorWrapper = styled.div`
  height: 40px;
`;

export const ErrorMessage = styled.p`
  margin: 5px 0;
  color: red;
  font-size: 14px;
`;
