import React from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { ForwardIcon } from './Icons';

interface LargeButtonProps {
  text: string
  onClick?(): void
}

export function LargeButton({ text, ...props }: LargeButtonProps) {
  return (
    <StyledButton {...props}>
      {text} <ForwardIcon />
    </StyledButton>
  );
}

const StyledButton = styled.button`
  border-style: none;
  background: none;
  color: ${Colors.blue};
  font-size: 30px;
  &:hover {
    cursor: pointer;
    svg {
      margin-left: 10px;
    }
  }
  svg{
    transition: .2s ease-out;
  }
`;
