import React, {
  useState, useCallback,
} from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { Text, BoldText } from '../styles/titles';
import { CopyText } from './CopyText';
import { ForwardIcon, BackIcon } from './Icons';

export interface SliderContent {
  heading: string
  text: string
  imageUrl: string
  copyValues?: { [key: string]: string }
}

interface ImageSliderProps {
  content: SliderContent[];
  setStepsFinished?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function ImageSlider({ content, setStepsFinished }: ImageSliderProps) {
  const [sliderIndex, setSliderIndex] = useState(0);

  const previousSlide = content[sliderIndex - 1];
  const nextSlide = content[sliderIndex + 1];

  // const handleChangeSlide = useCallback(
  //   (index: number) => {
  //     setSliderIndex(index);
  //   },
  //   [],
  // );

  const handleNext = useCallback(
    () => {
      const isLastSlide = sliderIndex === content.length - 1;
      if (isLastSlide) {
        return;
      }

      const isBeforeLastSlide = sliderIndex === content.length - 2;
      if (isBeforeLastSlide) {
        if (setStepsFinished) {
          setStepsFinished(true);
        }
      }

      setSliderIndex((prevState) => prevState + 1);
    },
    [sliderIndex, content, setStepsFinished],
  );

  const handleBack = useCallback(
    () => {
      const isFirstSlide = sliderIndex === 0;
      if (isFirstSlide) return;
      setSliderIndex((prevState) => prevState - 1);
    },
    [sliderIndex],
  );

  return (
    <Container>
      <Wrapper>

        <ChangeSlide onClick={handleBack} show={Boolean(previousSlide)}>
          <BackIcon />
        </ChangeSlide>

        <Slider>
          <SlideList>
            {content.map((slide, index) => (
              <Slide
                show={index === sliderIndex}
                sliderIndex={sliderIndex}
                key={index}
              >
                <CenterWrapper>

                  <TextWrapper>
                    <BoldText>{slide.heading.toUpperCase()}</BoldText>
                    <Text>{slide.text}</Text>
                    {slide.copyValues && Object.entries(slide.copyValues).map(([label, value]) => (
                      <CopyText label={label} text={value} />
                    ))}
                  </TextWrapper>
                  <MainImage src={slide.imageUrl} />
                </CenterWrapper>

              </Slide>
            ))}
          </SlideList>
        </Slider>

        <ChangeSlide onClick={handleNext} show={Boolean(nextSlide)}>
          <ForwardIcon />
        </ChangeSlide>
      </Wrapper>
      <Dots>
        {content.map((slide, index) => (
          <Dot
            type="radio"
            key={index}
            checked={index === sliderIndex}
          />
        ))}
      </Dots>

    </Container>
  );
}

const ChangeSlide = styled.a<{ show: boolean }>`
  font-size: 50px;
  align-self: center;
  cursor: pointer;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  padding: 5px;
  transition: .2s ease-out;
  &:hover {
    transform: scale(1.2);
  }
`;

const Wrapper = styled.div`
  display: flex;
`;

const Slider = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const SlideList = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 20rem;
  border-right: ${Colors.borderThin};
  margin-right: 20px;
  margin-left: 10px;
  white-space: pre-wrap;

`;

const Dots = styled.div`
  display: flex;
  align-self: center;
  margin: 15px;
`;

const Dot = styled.input`
  margin: 2px;
  transition: .2s ease-out;
  ${(props) => props.checked && `
    transform: scale(1.6);
    margin: 2px 8px;
  `};
`;

const MainImage = styled.img`
  height: 100%;
  width: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 20px;
`;

// const ImageWrapper = styled.div`
//   display: flex;
//   height: 100%;
//   width: 100%;
//   background: #5ac8fa;
// `;

const Slide = styled.div<{ show: boolean, sliderIndex: number }>`
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  position: relative;
  height: 50vh;
  flex-direction: row;

  transition:  0.5s ease-in-out;
  opacity:  ${(props) => (props.show ? '1' : '0')};
  transform: ${(props) => (`translateX(-${props.sliderIndex * 100}%)`)};
`;

const CenterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
`;
