import { withAuthenticator, WithAuthenticatorOptions, WithAuthenticatorProps } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import React, { useEffect } from 'react';
import {
  BrowserRouter as Router, Routes, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import HeaderBar from 'components/HeaderBar';
import Button from 'components/Button';
import { Header } from './components/LoginHeader';
import SetupMarketingCloud from './pages/SetupMarketingCloud/SetupMarketingCloud';
import { addCloseAppWarningEvent, removeCloseAppWarningEvent } from './helpers/closeAppWarning';

const MainContainer = styled.div`
  display: flex;
  height: 100vh;
  min-width: 1280px;
`;

const StyledButton = styled(Button)`
  margin-left: auto;
  align-self: start;
`;

function App({ signOut }: WithAuthenticatorProps) {
  useEffect(() => {
    addCloseAppWarningEvent();

    return () => {
      removeCloseAppWarningEvent();
    };
  }, []);

  return (
    <Router>
      <MainContainer>
        <Routes>
          <Route path="*" element={<SetupMarketingCloud />} />
        </Routes>
        <ToastContainer
          position="top-right"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="colored"
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <HeaderBar pageTitle="">
          <StyledButton
            onClick={signOut}
          >
            Sign out
          </StyledButton>
        </HeaderBar>
      </MainContainer>
    </Router>
  );
}

const options: WithAuthenticatorOptions = {
  hideSignUp: true,
  components: {
    Header,
  },
};

export default withAuthenticator(App, options);
