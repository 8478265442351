import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { CopyIcon, CheckIcon } from './Icons';
import { Text, BoldText } from '../styles/titles';

interface CopyTextProps {
  label?: string
  text: string
}

export function CopyText({ label, text }: CopyTextProps) {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(
    async () => {
      await navigator.clipboard.writeText(text);
      setCopied(true);
    },
    [text],
  );

  return (
    <Wrapper onClick={handleCopy} copied={copied}>
      <BoldText>{label}</BoldText>
      <Text>
        <>
          {copied ? (<CheckIcon />) : (<CopyIcon />)}
          {text}
        </>
      </Text>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ copied: boolean }>`
  margin-top: 10px;
  cursor: pointer;
  svg {
    margin-right: 5px;
    color: ${(props) => (props.copied ? Colors.darkGreen : Colors.darkGrey)};
  }
`;
