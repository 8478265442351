import styled from 'styled-components';

export const BoldText = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

export const Text = styled.p`
  font-size: 14px;
`;
