import React, { useState } from 'react';
import Button from '../../../components/Button';
import { ImageSlider, SliderContent } from '../../../components/ImageSlider';
import {
  ButtonWrapper, Container, SubTitle, LargeTitle,
} from '../../../styles/styles';

interface ClientSideInstallProps {
  handleNext(): void
  handleBack(): void
  companyName: string | undefined
}

export function ClientSideInstall({ handleNext, handleBack, companyName }: ClientSideInstallProps) {
  const [stepsFinished, setStepsFinished] = useState(false);

  const clientSideSliderContent: SliderContent[] = [
    {
      heading: 'Head to your Marketing Cloud Setup and Installed Packages',
      text: 'In the Installed Packages overview click on the New button in the top right corner',
      imageUrl: '/installation/webapp-installation/create-package.png',
    },
    {
      heading: 'Create Web App package',
      text: 'Give the package the name: "Activation Studio Web App". '
            + '\n\nClick on Save',
      imageUrl: '/installation/webapp-installation/webapp-new-package.png',
    },
    {
      heading: 'Add component',
      text: 'Next click on the Add Component button',
      imageUrl: '/installation/webapp-installation/webapp-new-component.png',
    },
    {
      heading: 'Component Type',
      text: 'Choose the option API Integration and click Next',
      imageUrl: '/installation/webapp-installation/webapp-first-component-type.png',
    },
    {
      heading: 'Integration Type',
      text: 'Choose the option Web App and click Next',
      imageUrl: '/installation/webapp-installation/webapp-first-component-integration.png',
    },
    {
      heading: 'Fill in properties',
      text: 'In the Redirect URIs fill in the value RedirectURI shown at the bottom. \n\n'
            + 'In the scope you have to tick Offline Access and select 30 days at Refresh Token Lifetime.\n\n'
            + 'Next scroll down to DATA\n\n '
            + 'Continues on next page...',
      imageUrl: '/installation/webapp-installation/webapp-first-component-properties.png',
      copyValues: { RedirectURI: `https://${companyName}.activation-studio.com/oauth2` },
    },
    {
      heading: 'Fill in properties',
      text: 'Under DATA check all the boxes under Data Extensions, File Locations and Tracking Events.\n\n'
            + 'Next you can click on Save',
      imageUrl: '/installation/webapp-installation/webapp-first-component-properties2.png',
    },
    {
      heading: 'Second component',
      text: 'Click on the Add Component button',
      imageUrl: '/installation/webapp-installation/webapp-add-second-component.png',
    },
    {
      heading: 'Component type',
      text: 'Select Marketing Cloud App and click Next ',
      imageUrl: '/installation/webapp-installation/webapp-second-component-type.png',
    },
    {
      heading: 'Marketing Cloud App Properties',
      text: 'In the Name field fill in "Activation Studio"\n'
            + 'For the Login Endpoint and Logout Endpoint fill in the values shown below',
      imageUrl: '/installation/webapp-installation/webapp-second-component-properties.png',
      copyValues: {
        LoginEndpoint: `https://${companyName}.activation-studio.com/login`,
        LogoutEndpoint: `https://${companyName}.activation-studio.com/logout`,
      },
    },
  ];

  if (!companyName) return <p>Something went wrong with processing your company name</p>;

  return (
    <Container>
      <LargeTitle>3. Create your Web App package</LargeTitle>
      <SubTitle>Here’s a step-to-step guide to install the second package in Marketing Cloud</SubTitle>

      <ImageSlider content={clientSideSliderContent} setStepsFinished={setStepsFinished} />

      <ButtonWrapper>
        <Button variant="neutral" onClick={handleBack}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNext} disabled={!stepsFinished}>
          Next Step
        </Button>
      </ButtonWrapper>
    </Container>
  );
}
