import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { Colors } from '../constants/colors';
import { ErrorMessage } from '../styles/styles';
import { BoldText } from '../styles/titles';
import { LockIcon } from './Icons';

interface NewInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  defaultValue?: never
  secure?: boolean
  error?: string | null | undefined
}

function Input({
  label, required, secure, error, ...props
}: NewInputProps) {
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  return (
    <Container>
      <Top>
        <BoldText>{label?.toUpperCase() || ''}</BoldText>
        <Asterisk>{required ? '*' : ''}</Asterisk>
      </Top>
      <InputWrapper>
        {secure && (<LockIcon />)}
        <StyledInput {...props} secure={secure} onFocus={onFocus} onBlur={onBlur} />
        {(error && focused) && (
          <ErrorMessage>{error}</ErrorMessage>
        )}
      </InputWrapper>
    </Container>
  );
}

export default Input;

const StyledInput = styled.input<{ secure?: boolean }>`
  width: 100%;
  min-height: 34px;
  
  background-color: ${Colors.grey};
  color: ${Colors.black};
  border-radius: 5px;
  border-style: none;
  padding: 7px 10px;
  margin-top: 5px;
  margin-bottom: 10px;

  ${(props) => props.secure && `
    padding-left: 30px;
  `}
  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: #f9f9f9;
    color: #ccc;
  }
`;

const InputWrapper = styled.div`
  svg {
    position: absolute;
    padding: 10px;
    margin-top: 5px;
    color: ${Colors.darkGrey};
  }
`;

const Top = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

const Asterisk = styled.span`
  margin-left: auto;
  color: ${Colors.red};
  `;

const Container = styled.div`
  width: calc(100% - 30px);
`;
