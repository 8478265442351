import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Colors } from '../constants/colors';
import { SpinnerIcon } from './Icons';

interface LoaderProps {
  size: 'small' | 'big';
}

function Loader(props: LoaderProps) {
  return (
    <LoadingContainer {...props}>
      <AnimatedSpinContainer>
        <SpinnerIcon />
      </AnimatedSpinContainer>
    </LoadingContainer>
  );
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
    transform-origin: center;
  }

  100% {
    transform: rotate(360deg);
    transform-origin: center;
  }
`;

const LoadingContainer = styled.div<{ size: 'small' | 'big' }>`
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.darkGrey};
  font-size: ${({ size }) => ((size === 'small') ? '14px' : '40px')};
`;

const AnimatedSpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  animation: ${spin} 1s linear infinite;
`;

export default Loader;
