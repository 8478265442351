import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import { ServerSideKeys } from '../../../handlers/useServerSideKeys';
import {
  ButtonWrapper, Container, InputWrapper, SubTitle, LargeTitle, ErrorMessage, ErrorWrapper,
} from '../../../styles/styles';

interface ServerSideKeysProps {
  handleNext(): void
  handleBack(): void
  serverSideKeys: ServerSideKeys
}

interface HoverPictures {
  [key: string]: string
}

const hoverPictures: HoverPictures = {
  standard: '/installation/server-side-keys/default.png',
  clientIdServer: '/installation/server-side-keys/server-client-id.png',
  clientSecretServer: '/installation/server-side-keys/server-client-secret.png',
  authUri: '/installation/server-side-keys/server-auth-uri.png',
};

export function ServerSideKeysForm({
  handleNext, handleBack, serverSideKeys,
}: ServerSideKeysProps) {
  const [currentHover, setCurrentHover] = useState<string>('standard');
  const [hoversValidate, setHoversValidate] = useState<boolean>(false);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      serverSideKeys.handleValue(event.currentTarget.name, event.currentTarget.value);
    },
    [serverSideKeys],
  );

  return (
    <Container>
      <LargeTitle>2. Fill in your Server Side package keys</LargeTitle>
      <SubTitle>
        Hover over the fields to see where to find the corresponding keys in your Marketing Cloud package
      </SubTitle>
      <Wrapper>

        <StyledInputWrapper>
          <Input
            value={serverSideKeys.formData.clientIdServer || ''}
            required
            secure
            onChange={handleOnChange}
            error={serverSideKeys.errorObject?.clientIdServer || null}
            onMouseEnter={() => setCurrentHover('clientIdServer')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="clientIdServer"
            label="Client ID Server"
            disabled={serverSideKeys.validated}
          />
          <Input
            value={serverSideKeys.formData.clientSecretServer || ''}
            required
            secure
            onChange={handleOnChange}
            error={serverSideKeys.errorObject?.clientSecretServer || null}
            onMouseEnter={() => setCurrentHover('clientSecretServer')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="clientSecretServer"
            label="Client Secret Server"
            disabled={serverSideKeys.validated}

          />
          <Input
            value={serverSideKeys.formData.authURI || ''}
            required
            secure
            onChange={handleOnChange}
            error={serverSideKeys.errorObject?.authURI || null}
            onMouseEnter={() => setCurrentHover('authUri')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="authURI"
            label="Authentication Base URI"
            disabled={serverSideKeys.validated}
          />
          <Input
            value={serverSideKeys.formData.customMID || ''}
            required={false}
            secure
            onChange={handleOnChange}
            error={serverSideKeys.errorObject?.customMID || null}
            onMouseEnter={() => setCurrentHover('standard')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="customMID"
            label="Custom Marketing Cloud Business Unit ID to validate"
            disabled={serverSideKeys.validated}
          />
          <ErrorWrapper>
            {hoversValidate && (
              <ErrorMessage>{serverSideKeys.errorMessage}</ErrorMessage>
            )}
          </ErrorWrapper>

          <div
            onMouseEnter={(event) => {
              setHoversValidate(true);
              event.stopPropagation();
            }}
            onMouseLeave={(event) => {
              setHoversValidate(false);
              event.stopPropagation();
            }}
          >
            <StyledButton
              onClick={serverSideKeys.handleValidate}
              disabled={!serverSideKeys.isValid || serverSideKeys.validated}

            >
              {serverSideKeys.isValidating ? (
                <Loader size="small" />
              ) : (
                'Validate'
              )}
            </StyledButton>
          </div>
        </StyledInputWrapper>

        <ImageWrapper>
          <Image show={currentHover === 'standard'} zIndex={1} src={hoverPictures.standard} />
          <Image show={currentHover === 'clientIdServer'} zIndex={2} src={hoverPictures.clientIdServer} />
          <Image show={currentHover === 'clientSecretServer'} zIndex={3} src={hoverPictures.clientSecretServer} />
          <Image show={currentHover === 'authUri'} zIndex={5} src={hoverPictures.authUri} />
        </ImageWrapper>

      </Wrapper>
      <ButtonWrapper>
        <Button
          variant="neutral"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          disabled={!serverSideKeys.validated}
        >
          Next step
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const StyledButton = styled(Button)`
  align-self: start;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Image = styled.img<{ show: boolean, zIndex: number }>`
  height: 60vh;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50px;
  margin-top: 50px;
  transition: .2s ease-out;
  z-index:  ${(props) => (props.zIndex)};
  opacity:  ${(props) => (props.show ? '1' : '0')};

`;

const StyledInputWrapper = styled(InputWrapper)`
  min-width: 400px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
`;
