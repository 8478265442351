import React, { useState } from 'react';
import Button from '../../../components/Button';
import { SliderContent, ImageSlider } from '../../../components/ImageSlider';
import {
  ButtonWrapper, Container, LargeTitle, SubTitle,
} from '../../../styles/styles';

interface ServerSideInstallProps {
  handleNext(): void
  handleBack(): void
}

const serverSideSliderContent: SliderContent[] = [
  {
    heading: 'Head to your Marketing Cloud Setup and Installed Packages',
    text: 'In the Installed Packages overview click on the New button in the top right corner',
    imageUrl: '/installation/server-side-installation/create-package.png',
  },
  {
    heading: 'Create Server-to-Server package',
    text: 'Give the package the name: "Activation Studio Server Side". '
          + '\n\nClick on Save',
    imageUrl: '/installation/server-side-installation/serverside-new-package.png',
  },
  {
    heading: 'Add component',
    text: 'Next click on the Add Component button',
    imageUrl: '/installation/server-side-installation/serverside-add-component.png',
  },
  {
    heading: 'Component Type',
    text: 'Choose the option API Integration and click Next',
    imageUrl: '/installation/server-side-installation/serverside-component-type.png',
  },
  {
    heading: 'Integration Type',
    text: 'Choose the option Server-to-Server and click Next',
    imageUrl: '/installation/server-side-installation/serverside-integration-type.png',
  },
  {
    heading: 'Scope',
    text: 'For the scope scroll down to AUTOMATION and tick all the boxes under Automation and all the'
          + ' boxes under Journey except Journey Delete.\n\nNext scroll down to CONTACTS and tick all the boxes under'
          + ' Audiences'
          + ' and all the boxes under List and Subscribers.\n\nNext scroll down to DATA.',
    imageUrl: '/installation/server-side-installation/serverside-scope1.png',
  },
  {
    heading: 'Continuation of Scope',
    text: 'Under DATA  tick all the boxes under Data Extensions, File Locations and Tracking Events. '
          + '\n\nNext scroll down to PROVISIONING.',
    imageUrl: '/installation/server-side-installation/serverside-scope2.png',
  },
  {
    heading: 'Continuation of Scope - 2',
    text: 'Under PROVISIONING tick the Read permission under Users,\n\n'
          + 'Next click on the Save button.',
    imageUrl: '/installation/server-side-installation/serverside-scope3.png',
  },
];

export function ServerSideInstall({ handleNext, handleBack }: ServerSideInstallProps) {
  const [stepsFinished, setStepsFinished] = useState(false);
  return (
    <Container>
      <LargeTitle>1. Create your Server Side package</LargeTitle>
      <SubTitle>Here’s a step-to-step guide to install the Server-to-Server package in Marketing Cloud</SubTitle>
      <ImageSlider content={serverSideSliderContent} setStepsFinished={setStepsFinished} />
      <ButtonWrapper>
        <Button variant="neutral" onClick={handleBack}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNext} disabled={!stepsFinished}>
          Next step
        </Button>
      </ButtonWrapper>
    </Container>
  );
}
