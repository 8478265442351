import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
// eslint-disable-next-line import/extensions
import { Amplify } from 'aws-amplify';
import App from './App';
import awsExports from './aws-exports';

Amplify.configure(awsExports);

// eslint-disable-next-line no-undef
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // eslint-disable-next-line react/jsx-filename-extension
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
