import React from 'react';
import styled from 'styled-components';

const HeaderBarContainer = styled.div`
  height: 60px;
  z-index: 2;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 10px;
  color: black;
  background-color: clear;
  display: flex;
  align-items: center;
`;

const HeaderTitle = styled.h1`
  margin-right: 20px;
`;

interface HeaderBarProps {
  pageTitle: string;
  children?: React.ReactNode;
}

function HeaderBar({ pageTitle, children }: HeaderBarProps) {
  return (
    <HeaderBarContainer>
      <HeaderTitle>{pageTitle}</HeaderTitle>
      {children}
    </HeaderBarContainer>
  );
}

export default HeaderBar;
