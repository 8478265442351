import { useForm, Form } from '../hooks/useForm';
import { WebAppSecrets } from '../types';

export interface WebAppKeys extends Form {
  formData: WebAppSecrets
}

export const useWebAppKeys = (): WebAppKeys => {
  const { formData, ...form } = useForm({
    fields: {
      clientIdWebApp: {
        type: 'string',
        required: true,
        maxLength: 254,
      },
      clientSecretWebApp: {
        type: 'string',
        required: true,
        maxLength: 254,
      },
    },
  });

  return {
    formData,
    ...form,
  };
};
