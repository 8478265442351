export const Colors = {
  white: '#FFFFFF',
  black: '#2E1E0F',
  grey: '#F3F3F3',
  darkGrey: '#C2C2C2',
  red: '#CE2D4F',
  blue: '#339AF0',
  green: '#4CB944',
  darkGreen: '#356E48',
  yellow: '#FFC759',
  primary: '#4C5981',
  primaryActive: '#3D4A6F',
  borderThin: 'thin solid #F3F3F3',
  shadowSmall: '0px 0px 5px rgba(0,0,0,0.15)',
  shadowBig: '0px 0px 10px 5px rgba(0,0,0,0.2)',
};
