import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import { Form } from '../../../hooks/useForm';
import {
  ButtonWrapper, Container, InputWrapper, SubTitle, LargeTitle, ErrorMessage,
} from '../../../styles/styles';

interface ClientSideKeysProps {
  handleNext(): void
  handleBack(): void
  form: Form
}

interface HoverPictures {
  [key: string]: string
}

const hoverPictures: HoverPictures = {
  standard: '/installation/webapp-keys/webapp-keys-default.png',
  clientId: '/installation/webapp-keys/webapp-keys-clientid.png',
  clientSecret: '/installation/webapp-keys/webapp-keys-clientsecret.png',
};

export function ClientSideKeys({
  handleNext, handleBack, form,
}: ClientSideKeysProps) {
  const [currentHover, setCurrentHover] = useState<string>('standard');

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => (
    form.handleValue(event.currentTarget.name, event.currentTarget.value)
  );

  return (
    <Container>
      <LargeTitle>5. Fill in your Web App package keys</LargeTitle>
      <SubTitle>Hover over the fields to see the corresponding keys in your package</SubTitle>
      <Wrapper>
        <StyledInputWrapper>
          <Input
            value={form.formData.clientIdWebApp || ''}
            required
            secure
            onChange={handleOnChange}
            onMouseEnter={() => setCurrentHover('clientId')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="clientIdWebApp"
            label="Client ID Web App"
          />
          <Input
            value={form.formData.clientSecretWebApp || ''}
            required
            secure
            onChange={handleOnChange}
            onMouseEnter={() => setCurrentHover('clientSecret')}
            onMouseLeave={() => setCurrentHover('standard')}
            name="clientSecretWebApp"
            label="Client Secret Web App"
          />
          {form.errorMessage && (
            <ErrorMessage>{form.errorMessage}</ErrorMessage>
          )}

        </StyledInputWrapper>

        <ImageWrapper>
          <Image show={currentHover === 'standard'} zIndex={1} src={hoverPictures.standard} />
          <Image show={currentHover === 'clientId'} zIndex={2} src={hoverPictures.clientId} />
          <Image show={currentHover === 'clientSecret'} zIndex={3} src={hoverPictures.clientSecret} />
        </ImageWrapper>
      </Wrapper>
      <ButtonWrapper>
        <Button
          variant="neutral"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          disabled={!form.isValid}
        >
          Next step
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const ImageWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Image = styled.img<{ show: boolean, zIndex: number }>`
  height: 60vh;
  width: auto;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 50px;
  margin-top: 50px;
  transition: .2s ease-out;
  z-index:  ${(props) => (props.zIndex)};
  opacity:  ${(props) => (props.show ? '1' : '0')};

`;

const StyledInputWrapper = styled(InputWrapper)`
  min-width: 400px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
`;
