import { API, Auth } from 'aws-amplify';
import {
  useState, useCallback, SetStateAction, Dispatch,
} from 'react';
import { toast } from 'react-toastify';
import { Authorization } from '../hooks/useAuth';
import { useForm, Form } from '../hooks/useForm';
import { ServerSideSecrets } from '../types';

export interface ServerSideKeys extends Form {
  validated: boolean
  isValidating: boolean
  handleValidate(): void
  formData: ServerSideSecrets
}

type SecretsRequest = {
  clientIdServer: string,
  clientSecretServer: string,
  enterpriseId: string | number,
  customMID: number,
  authURI: string
};

export const useServerSideKeys = (authorization: Authorization): ServerSideKeys => {
  const [validated, setValidated] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const { formData, ...form } = useForm({
    fields: {
      clientIdServer: {
        type: 'string',
        required: true,
        maxLength: 254,
      },
      clientSecretServer: {
        type: 'string',
        required: true,
        maxLength: 254,
      },
      authURI: {
        type: 'httpUrl',
        required: true,
        maxLength: 254,
      },
      customMID: {
        type: 'string',
        required: false,
        maxLength: 20,
      },
    },
  });

  const handleValidate = useCallback(
    async () => {
      if (!authorization.token || !authorization.tokenBody) {
        toast.error('You need to be logged in.');
        return;
      }
      try {
        const currentSession = await Auth.currentSession();
        if (!currentSession.isValid()) {
          toast.error('Your session is expired. Please login again');
          Auth.signOut();
        }
      } catch (exc) {
        toast.error('Your session is expired. Please login again');
        Auth.signOut();
      }

      setIsValidating(true);
      const secrets: SecretsRequest = {
        clientIdServer: formData.clientIdServer,
        clientSecretServer: formData.clientSecretServer,
        enterpriseId: authorization.tokenBody['custom:enterpriseID'],
        customMID: formData.customMID,
        authURI: formData.authURI,
      };

      const response = await validateMarketingCloudSecrets(secrets, authorization, setIsValidating);

      if (response) {
        toast.success(response.message);
        setIsValidating(false);
        setValidated(true);
      }
    },
    [formData, authorization],
  );

  return {
    validated,
    isValidating,
    handleValidate,
    formData,
    ...form,
  };
};

const validateMarketingCloudSecrets = async (
  secrets: SecretsRequest,
  authorization: Authorization,
  setIsValidating: Dispatch<SetStateAction<boolean>>,
  retries = 2,
): Promise<any> => {
  const response = await API.post('marketingCloudValidation', '/validate/serverside', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: authorization.token,
    },
    body: secrets,
  }).catch(async (error) => {
    if (retries > 0) {
      return delay(10000) // this adds 10 seconds of delay before retry
        .then(
          await validateMarketingCloudSecrets(secrets, authorization, setIsValidating, retries - 1),
        );
    }

    let errorMessage = 'Could not validate secrets. Please make sure the secrets are correct and try again.';

    if (error.response && error.response.status === 400 && error.response.data?.message) {
      errorMessage = error.response.data?.message;
    }

    toast.error(errorMessage, {
      autoClose: 10000,
      bodyStyle: {
        whiteSpace: 'pre-line',
      },
    });
    setIsValidating(false);
    return error;
  });
  return response;
};

const delay = (waitTimeInMilliSeconds: number, val?: any) => new Promise(
  // the delay function is only there to wait for 10 seconds.
  // No  value will be passed, and therefore, no value will be returned
  // eslint-disable-next-line no-promise-executor-return
  (resolve) => setTimeout(resolve, waitTimeInMilliSeconds, val),
);
