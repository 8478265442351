import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LargeButton } from '../../../components/LargeButton';
import { Container, LargeTitle, SubTitle } from '../../../styles/styles';

interface GetStartedProps {
  handleNext(): void
}

export function GetStarted({ handleNext }: GetStartedProps) {
  return (
    <Container>
      <LargeTitle>Welcome to Activation Studio</LargeTitle>
      <SubTitle>Follow this step-to-step guide to install Activation Studio in your Marketing Cloud</SubTitle>
      <CenterButton>
        <LargeButton
          text="Start installation"
          onClick={handleNext}
        />
      </CenterButton>
      <Image src="/installation/cover-picture.png" alt="2 Happy people in a gradient" />
    </Container>
  );
}

const AnimatedGradient = keyframes`
  0% {background-position:90% 0}
  50% {background-position:10% 100%}
  100% {background-position:90% 0}
`;

const Image = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  background: linear-gradient(220deg, rgba(253,55,116,1), rgba(151,71,255,1));
  background-size: 200% 200%;
  animation: ${AnimatedGradient} 7s ease infinite;
`;

const CenterButton = styled.div`
  display: flex;
  flex: 2; 
`;
