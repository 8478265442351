import React, { useCallback, useState, useMemo } from 'react';
import useValidation, { Validation, ValidationProps } from './useValidation';

export type DataKey = string;
export type DataValue = any;
export type Data = { [key: DataKey]: DataValue } | any;

interface FormProps extends ValidationProps {

}

export interface Form extends Validation {
  formData: Data,
  formState: Data,
  setFormData: React.Dispatch<any>
  handleValue(key: DataKey, value: DataValue): void
}

export const useForm = ({ fields, data }: FormProps): Form => {
  const [formState, setFormData] = useState<Data>({});

  const formData = useMemo(() => ({
    ...data,
    ...formState,
  }), [data, formState]);

  const { isValid, errorMessage, errorObject } = useValidation({
    data: formState,
    fields,
  });

  const handleValue = useCallback((key: DataKey, value: DataValue) => {
    setFormData((prevData: Data) => ({
      ...prevData || {},
      [key]: value,
    }));
  }, []);

  return {
    formData,
    formState,
    setFormData,
    isValid,
    errorMessage,
    errorObject,
    handleValue,
  };
};
