import React, { ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

interface ButtonStyle {
  variant?: 'primary' | 'primary-outlined' | 'neutral' | 'danger'
  size?: 'sm' | 'lg'
}

interface ButtonProps extends ButtonStyle {
  onClick?: () => void
  children?: ReactNode
  disabled?: boolean
  className?: string
}

interface LinkProps extends ButtonStyle {
  to: any
  children?: ReactNode
  disabled?: boolean
  className?: string
}

interface ExternalLinkProps extends ButtonStyle {
  href: string
  children?: ReactNode
  disabled?: boolean
  className?: string
}

const buttonVariantMap = {
  primary: {
    default: {
      color: '#fff',
      backgroundColor: '#0070d2',
      border: 'thin solid #0070d2',
    },
    hover: {
      color: '#fff',
      backgroundColor: '#005cad',
      border: 'thin solid #005cad',
    },
    disabled: {
      color: '#3b91dd',
      backgroundColor: '#d7e5f1',
      border: 'thin solid #d7e5f1',
    },

  },
  'primary-outlined': {
    default: {
      color: '#0070d2',
      backgroundColor: 'transparent',
      border: 'thin solid #0070d2',
    },
    hover: {
      color: '#005cad',
      backgroundColor: 'transparent',
      border: 'thin solid #005cad',
    },
    disabled: {
      color: '#3b91dd',
      backgroundColor: 'transparent',
      border: 'thin solid #3b91dd',
    },
  },
  neutral: {
    default: {
      color: '#111',
      backgroundColor: '#eee',
      border: 'thin solid #eee',
    },
    hover: {
      color: '#111',
      backgroundColor: '#ddd',
      border: 'thin solid #ddd',
    },
    disabled: {
      color: '#ccc',
      backgroundColor: '#fefefe',
      border: 'thin solid #fefefe',
    },
  },
  danger: {
    default: {
      color: '#fff',
      backgroundColor: '#d32020',
      border: 'thin solid #d32020',
    },
    hover: {
      color: '#fff',
      backgroundColor: '#bb1919',
      border: 'thin solid #bb1919',
    },
    disabled: {
      color: '#e75858',
      backgroundColor: '#f8a4a4',
      border: 'thin solid #f8a4a4',
    },
  },
};

const buttonSizeMap = {
  sm: '0px 12px',
  lg: '0px 18px',
};

function Button({
  children, ...props
}: ButtonProps) {
  return (
    <StyledButton {...props}>
      {children}
    </StyledButton>
  );
}

Button.defaultProps = {
  variant: 'primary',
  size: 'sm',
};

export function Link({
  children, ...props
}: LinkProps) {
  return (
    <StyledLink {...props}>
      {children}
    </StyledLink>
  );
}

Link.defaultProps = {
  variant: 'primary',
  size: 'sm',
};

export function ExternalLink({
  children, ...props
}: ExternalLinkProps) {
  return (
    <StyledA {...props} target="_blank">
      {children}
    </StyledA>
  );
}

ExternalLink.defaultProps = {
  variant: 'primary',
  size: 'sm',
};

const buttonStyle = css<ButtonStyle>`
  color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].default.color};
  border: ${(props) => buttonVariantMap[props?.variant || 'neutral'].default.border};
  background-color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].default.backgroundColor};
  cursor: pointer;
  padding: ${(props) => buttonSizeMap[props?.size || 'sm']};
  border-radius: 3px;
  font-weight: 500;
  font-size: 11px;
  transition: 0.2s;
  height: 25px;
  
  svg {
    height: 11px;
  }

  &:not(:disabled):hover {
    color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].hover.color};
    border: ${(props) => buttonVariantMap[props?.variant || 'neutral'].hover.border};
    background-color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].hover.backgroundColor};
  }

  & + button, 
  & + a {
    margin-left: 6px;
  }
 

  &:disabled {
    color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].disabled.color};
    border: ${(props) => buttonVariantMap[props?.variant || 'neutral'].disabled.border};
    background-color: ${(props) => buttonVariantMap[props?.variant || 'neutral'].disabled.backgroundColor};
    cursor: not-allowed;
  }
`;

const StyledButton = styled.button<ButtonStyle>`
  ${buttonStyle};
`;

const StyledLink = styled(RouterLink) <ButtonStyle>`
  ${buttonStyle};
  text-decoration: none;
  line-height: 24px;
  display: inline-block;
`;

const StyledA = styled.a<ButtonStyle>`
  ${buttonStyle};
  text-decoration: none;
  line-height: 24px;
  display: inline-block;
`;

export default Button;
