import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Loader from '../../../components/Loader';
import {
  ButtonWrapper, Container, InputWrapper, SubTitle, LargeTitle,
} from '../../../styles/styles';
import { AllSecrets } from '../../../types';

interface ControlProps {
  handleCreate(): void
  handleBack(): void
  allSecrets: AllSecrets
  creatingSecrets: boolean
}

export function Control({
  handleBack, handleCreate, allSecrets, creatingSecrets,
}: ControlProps) {
  if (creatingSecrets) {
    return (
      <LoadingContainer>
        <Loader size="big" />
      </LoadingContainer>
    );
  }
  return (
    <Container>
      <LargeTitle>6. Review</LargeTitle>
      <SubTitle>Check if everything is correct</SubTitle>
      <InputWrapper>
        {Object.entries(allSecrets).map(([key, value]) => (
          <Input
            value={value}
            secure
            disabled
            label={key}
          />
        ))}
      </InputWrapper>
      <ButtonWrapper>
        <Button
          variant="neutral"
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleCreate}
          disabled={creatingSecrets}
        >
          Finish
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  height: 100%;
`;
