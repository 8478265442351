import { useMemo } from 'react';

export interface FieldOptions {
  type: 'list' | 'boolean' | 'number' | 'float' | 'string' | 'object' | 'enum' | 'url' | 'httpUrl' | 'alphaNumericDash'
  required?: boolean
  maxLength?: number
  minLength?: number
  maxValue?: number
  minValue?: number
  regex?: RegExp
  choices?: string[] // for type enum
  child?: FieldOptions // for type list
  children?: { [key: string]: FieldOptions } // for type is object
}

export type FieldName = string;
export type Fields = { [key: FieldName]: FieldOptions };

// return the first found error
const validator = (options: FieldOptions, value : any) : string | undefined => {
  const {
    type,
    required,
    maxLength,
    minLength,
    maxValue,
    minValue,
    regex,
    child,
    choices,
    children,
  } = options;

  // 0 or false are valid values, null, undefined or empty string not
  const isValue = value !== undefined && value !== null && value !== '';

  if (required && !isValue) {
    return 'field  is required';
  }

  if (isValue) {
    // check types
    if (type === 'boolean' && !(value === true || value === false)) {
      return 'not a boolean';
    }
    if ((type === 'number' || type === 'float') && !Number.isFinite(value)) {
      return 'not a number';
    }
    if (type === 'list' && !Array.isArray(value)) {
      return 'not an array';
    }
    if (type === 'list' && typeof value !== 'object') {
      return 'not a object';
    }

    if (type === 'alphaNumericDash' && !(/^[\w-]*$/.test(value))) {
      return 'Only numbers letters, underscore and dashes are allowed';
    }

    if (type === 'url') {
      try {
        // eslint-disable-next-line no-new
        new URL(value);
      } catch (e) {
        return 'The URL is formatted incorrectly.';
      }
    }

    if (type === 'httpUrl') {
      try {
        // eslint-disable-next-line no-new
        const url = new URL(value);
        if (!['http:', 'https:'].includes(url.protocol)) {
          return 'no http(s) protocol';
        }
        if (!url.hostname.includes('.')) {
          return 'tld only host is not accepted';
        }
      } catch (e) {
        return 'The URL is formatted incorrectly.';
      }
    }

    // check values
    if (regex && !regex.test(value)) {
      return 'does not match regex';
    }
    if (maxLength && value.length > maxLength) {
      return 'exceeds max length';
    }
    if (minLength && value.length < minLength) {
      return 'exceeds min length';
    }
    if (maxValue && value > maxValue) {
      return 'exceeds max value';
    }
    if (minValue && value < minValue) {
      return 'exceeds min value';
    }
    if (type === 'enum' && choices && !choices.includes(value)) {
      return 'not included in enum';
    }

    // validator for list. all children should be valid
    if (type === 'list' && child) {
      return value
        .map((childValue: any) => validator(child, childValue))
        .find((error : string | undefined) => !!error); // return first found error
    }

    // validator for object. all key/value pairs should be valid
    if (type === 'object' && children) {
      return Object.keys(value)
        .map((key) => {
          if (children[key]) return validator(children[key], value[key]);
          return undefined;// no validator for key
        })
        .find((error : string | undefined) => !!error); // return first found error
    }
  }

  return undefined; // nothing to complain about
};

export interface Validation {
  isValid: boolean
  errorMessage: string | null
  errorObject: { [field: string]: string } | null
}

export interface ValidationProps {
  fields?: Fields
  data?: any
}

const useValidation = ({ fields, data } : ValidationProps) : Validation => {
  // validate all fields form

  let errorMessage: null | string = null;
  let errorObject: { [field: string]: string } | null = null;

  const isValid = useMemo(() : boolean => {
    if (!fields) return false;
    if (!data) return false;
    return Object.keys(fields).every((field) => {
      const error = validator(fields[field], data[field]);
      if (!error) return true;
      errorMessage = `${field} has an error: ${JSON.stringify(error)}`;
      errorObject = { [field]: JSON.stringify(error) };
      return false;
    });
  }, [data, fields]);

  return {
    isValid,
    errorMessage,
    errorObject,
  };
};

export default useValidation;
