import { Auth } from 'aws-amplify';
import { useState, useEffect, useMemo } from 'react';

export interface Authorization {
  token: string
  tokenBody: {
    'custom:region': string
    'custom:enterpriseID': string
    'custom:organization': string
    email: string
    phone_number: string
  } | null
}

export const useAuth = (): Authorization => {
  const [token, setToken] = useState<string>('');

  function getToken() {
    return Auth.currentSession();
  }

  useEffect(() => {
    if (!token) {
      getToken().then((userToken) => setToken(userToken.getIdToken().getJwtToken()));
    }
  }, [token]);

  const tokenBody = useMemo(() => {
    if (!token) return null;
    const baseTokenBody = token.split('.')[1];
    const decodedTokenBody = atob(baseTokenBody);
    return JSON.parse(decodedTokenBody);
  }, [token]);

  return {
    token,
    tokenBody,
  };
};
