function beforeUnLoad(e: BeforeUnloadEvent) {
  e.preventDefault();
  e.stopPropagation();
  e.returnValue = 'Setup has not been completed. If you leave now, you will lose your progress';
}

export function addCloseAppWarningEvent() {
  window.addEventListener('beforeunload', beforeUnLoad, { capture: true });
}

export function removeCloseAppWarningEvent() {
  window.removeEventListener('beforeunload', beforeUnLoad, { capture: true });
}
