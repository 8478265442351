import {
  faChevronLeft, faChevronRight, faLock, faCopy, faCheck, faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import React from 'react';

interface IconProps extends Omit<FontAwesomeIconProps, 'icon'> {
}

export function BackIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faChevronLeft} {...props} />;
}
export function ForwardIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faChevronRight} {...props} />;
}

export function LockIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faLock} {...props} />;
}

export function CopyIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faCopy} {...props} />;
}

export function CheckIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faCheck} {...props} />;
}

export function SpinnerIcon(props: IconProps) {
  return <FontAwesomeIcon icon={faSpinner} {...props} />;
}
