import { Image, useTheme } from '@aws-amplify/ui-react';
import React from 'react';
import styled from 'styled-components';

export function Header() {
  const { tokens } = useTheme();

  return (
    <StyledImage
      alt="logo"
      src="/activation-studio-logo.png"
      padding={tokens.space.medium}
    />
  );
}

const StyledImage = styled(Image)`
  background-color: white
`;
