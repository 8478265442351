import React from 'react';
import styled from 'styled-components';
import Button from '../../../components/Button';
import {
  ButtonWrapper, Container, LargeTitle, SubTitle,
} from '../../../styles/styles';

interface OverviewProps {
  handleNext(): void
  handleBack(): void
}

export function Overview({ handleBack, handleNext }: OverviewProps) {
  return (
    <Container>
      <LargeTitle>Overview</LargeTitle>
      <SubTitle>Here’s a quick overview of the steps we need to follow</SubTitle>

      <ListView>
        <ListItem>1. Create Server-to-Server package</ListItem>
        <ListItem>2. Fill in keys Server-to-Server package</ListItem>
        <ListItem>3. Create Web App package</ListItem>
        <ListItem>4. Fill in keys Web App package</ListItem>
        <ListItem>5. Review your submission</ListItem>
        <ListItem>6. Finish</ListItem>
      </ListView>
      <ButtonWrapper>

        <Button variant="neutral" onClick={handleBack}>
          Back
        </Button>
        <Button variant="primary" onClick={handleNext}>
          Next step
        </Button>
      </ButtonWrapper>
    </Container>
  );
}

const ListView = styled.ul`
  display: flex;
  margin-top: 120px;
  flex-direction: column;
  list-style: none;
`;

const ListItem = styled.li`
  font-size: 20px;
`;
