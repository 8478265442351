import React from 'react';
import styled from 'styled-components';

interface ProgressBarProps {
  progress: number
}

export function ProgressBar({ progress }: ProgressBarProps) {
  return (
    <Wrapper>
      <Bar>
        <Progress progress={progress} />
      </Bar>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: auto;
  width: 100%;
`;

const Bar = styled.div`
  display: flex;
  flex: 1;
  height: 30px;
`;

const Progress = styled.div<{ progress: number }>`
  background: linear-gradient(90deg, rgba(253,55,116,1) 0%, rgba(151,71,255,1) 100%);
  transition: width 1s ease-out;
  width: ${(props) => `${props.progress}%`};
`;
